@tailwind base;
@tailwind components;
@tailwind utilities;

/* blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure, */
p,
pre {
  margin: 0;
}
