@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Thin.ttf');
  src: url('Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-ThinItalic.ttf');
  src: url('Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-ExtraLight.ttf');
  src: url('Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-ExtraLightItalic.ttf');
  src: url('Montserrat-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Light.ttf');
  src: url('Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-LightItalic.ttf');
  src: url('Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Regular.ttf');
  src: url('Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Italic.ttf');
  src: url('Montserrat-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Medium.ttf');
  src: url('Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-MediumItalic.ttf');
  src: url('Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-SemiBold.ttf');
  src: url('Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-SemiBoldItalic.ttf');
  src: url('Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Bold.ttf');
  src: url('Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-BoldItalic.ttf');
  src: url('Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-ExtraBold.ttf');
  src: url('Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-ExtraBoldItalic.ttf');
  src: url('Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Black.ttf');
  src: url('Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-BlackItalic.ttf');
  src: url('Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
