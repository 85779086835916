@import '../../../app/css/variables';
// Mobile css

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../../../app/assets/fonts/Barlow/BarlowCondensed-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('../../../app/assets/fonts/Montserrat/Montserrat-Medium.otf')
    format('opentype');
}

.tw-signup {
  // background-image: url('../images/bg.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: 'Montserrat Medium';
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  color: $tw-secondary;
  font-size: 20px;

  .tw-title {
    font-size: 58px;
    font-family: 'Barlow Condensed';
    color: white;
  }

  .tw-textfield {
    font-size: 12px;
    color: #1c355d;
  }
  .tw-checkbox {
    font-size: 9px;
    color: $tw-light-grey;
  }
  .tw-alreadyAccount {
    font-size: 20px;
  }
  .tw-forgotPassword {
    font-size: 14px;
    margin-top: 10px;
  }
  .tw-noAccount {
    font-size: 18px;
    color: $fontBaseColor;
    margin-bottom: 10px;
  }

  .tw-logo {
    width: 300px;
  }

  .vl {
    border: 1px solid $tw-light-grey;
    border-image: linear-gradient(
      to bottom,
      white 25%,
      $tw-light-grey 25%,
      $tw-light-grey 75%,
      white 25%
    );
    border-image-slice: 1;
  }

  .col-image {
    width: 300px;
    height: 400px;
    margin-top: 10px;
  }

  input[type='checkbox'] {
    border: 1px solid $tw-primary;
  }

  .select-arrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../../../app/assets/img/selectArrow.png) no-repeat right !important;
    background-size: 9.2px 9.2px !important;
    background-position: 95% 50% !important;
  }

  //MOBILE
  @media (max-width: 480px) {
    width: calc(100%);
    .tw-title {
      font-size: 30px;
    }
    .tw-alreadyAccount {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .tw-logo {
      width: 90%;
    }
  }

  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    .col-image {
      margin-top: 70px;
      width: 230px;
      height: 250px;
    }
  }
}
