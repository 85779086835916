.api-loader {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  background-color: #c5c5c5;
  height: 100%;
  width: 100%;
  z-index: 100;
  .spinner {
    position: relative;
    top: 50%;
    left: 50%;
    z-index: 1001;
  }
}
