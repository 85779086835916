/* make the customizations here*/
// $theme-colors: (
//     "info": tomato,
//     "danger": teal
// );

/* import bootstrap to set changes */

@import '~bootstrap/scss/bootstrap';
@import './variables.scss';
