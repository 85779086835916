$tw-background-root-bg: #f1f0f5; //Define variables over here
$tw-primary: #33ABE1;

$tw-secondary: #3c4069;
$tw-secondary: #384067; // G Drive

$tw-green: #4ab342;
$tw-green: #37b24a; // G Drive

$tw-light-blue: #b5cddc; // G Drive

$tw-light-grey: #f4f3f8;
$tw-light-grey: #d9d9d9; // G Drive

$tw-peacock: #231f20; // G Drive looks incorrect
$tw-peacock: #154778;

$loading-bg: #49ace2;
$activeIcon: #34a9df;
$nonActiveIcon: #f9f9f9;
$fontBaseColor: #154576;
